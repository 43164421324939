<template>
  <div class="main">
    <div class="d-flex flex-column justify-content-center">
      <img src="../../../assets/image/ChargeMeContactBook/LineBot/LineBot_Barcode.png" alt="LineBot_Barcode" />
      <div class="lineFriend" v-if="$store.getters.mobileSize"><iframe id="iframeId" @load="loadFrame" class="addFriend" :src="lineHtml" frameborder="0"></iframe></div>
    </div>
    <div class="left">
      <div class="mb-3 top">
        <h1 class="mb-1">喬米秘書</h1>
        <div>(官方專屬line@)</div>
      </div>
      <div class="my-3 lineId">
        <p class="mb-1">Line搜尋ID：</p>
        <p>@503mwepm (喬米聯絡簿)</p>
      </div>
      <div class="my-3">
        <div class="mb-1">「喬米秘書」</div>
        <div class="intro">是ChargeMe喬米聯絡簿平台，與手機連結的小幫手，主要功能有「系統訊息主動通知」、「手機快速連結」</div>
      </div>
      <div class="mt-3 inform">
        <div>[系統訊息主動通知]</div>
        <ul>
          <li>作業清單逾期</li>
          <li>剩餘課堂不足</li>
          <li>尚未繳款堂數</li>
        </ul>
      </div>
      <div class="mt-3 inform">
        <div>[手機快速連結]</div>
        <ul>
          <li>課程選單</li>
          <li>課表</li>
          <li>作業清單</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LineBot",
  data() {
    return {
      lineHtml: "https://social-plugins.line.me/widget/friend?lineId=%40503mwepm&lang=zh_Hant&type=friend&id=3&origin=https%3A%2F%2Fdevelopers.line.biz%2Fzh-hant%2Fdocs%2Fline-social-plugins%2Finstall-guide%2Fusing-add-friend-buttons%2F%23create-button&title=%E5%BB%BA%E7%AB%8B%E3%80%8C%E5%8A%A0%E5%85%A5%E5%A5%BD%E5%8F%8B%E3%80%8D%E6%8C%89%E9%8D%B5%20%7C%20LINE%20Developers&env=REAL",
    };
  },
  methods: {},
  mounted() {
    this.log_PageInfo("LineBot", "9");
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 2rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.top {
  color: rgb(52, 52, 236);
}
.lineId {
  color: rgb(94, 94, 245);
}
img {
  margin-top: 2rem;
  width: 10rem;
  height: 10rem;
  margin: 1rem auto;
}
h1 {
  font-size: 2rem;
  font-weight: bold;
}
.intro {
  line-height: 1.2rem;
}
.inform {
  margin: auto;
  ul {
    text-align: start;
    margin-top: 0.5rem;
    li {
      line-height: 1.5rem;
      width: 50%;
      margin: auto;
    }
  }
}
.lineFriend {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  .addFriend {
    width: 5rem;
    height: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .main {
    flex-direction: row;
    padding: 10rem;
  }
  img {
    width: 20rem;
    height: 20rem;
    // margin: auto 2rem;
  }
  .left {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto 2rem;
  }
  .inform {
    ul {
      li {
        line-height: 1.5rem;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
